$publicPath:'/tantan-door-admin';





















































































































































































@import "./../assets/css/global-variable.scss";
.g-main-box {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: $fc-bg-shallow;
  .u-nav-box {
    overflow-y: auto;
    overflow-x: hidden;
    width: 208px;
    min-width: 208px;
    background-color: #206e55;
    box-shadow: 2px 0px 8px rgba(4, 20, 1, 0.08);
    border-radius: 0px 0px 20px 0px;
    position: relative;
    padding-top: 75px;
    transition: all ease 0.3s;
    border-bottom-right-radius: 20px;
    &.s-expand {
      width: 64px;
      min-width: 64px;
      /deep/ .el-menu i {
        margin-left: 0 !important;
      }
    }

    .menu-logo-svg {
      width: 100px;
      height: 50px;
      background: url("~assets/img/logo.png") no-repeat;
      background-size: 100%;
      margin-left: -22px;
    }

    .u-h3 {
      position: absolute;
      left: 0px;
      top: 20px;
      background-size: cover;
      line-height: 40px;
      font-size: 18px;
      transition: all ease 0.3s;
      padding-left: 45px;
      width: 100px;
      height: 64px;
      position: absolute;
      z-index: 30;

      &.s-expand {
        width: 40px;
        height: 40px;
        padding-left: 0px;
        left: 12px;
      }
    }
    .logo-box {
      width: 156px;
      height: 40px;
      position: absolute;
      left: 30px;
      top: 20px;
      &.s-expand {
        left: 12px;
      }
      .logo-img {
        width: 156px;
        height: 40px;
        &.s-expand {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
  .u-main {
    display: flex;
    //flex: 1;
    width: calc(100% - 208px);
    flex-direction: column;
    transition: all ease 0.3s;
    &[data-mini] {
      width: calc(100% - 65px);
    }
    .m-header {
    }
    .u-body {
      overflow-y: auto;
      padding: 30px 30px 30px;
      flex: 1;
      box-sizing: border-box;
      position: relative;
      background: #f5f7f9;
      .u-bread {
        position: absolute;
        top: 18px;
        left: 20px;
        .u-refresh {
          float: left;
          cursor: pointer;
          color: $fc-theme;
        }
        .u-pos {
          float: left;
          margin-left: 10px;
        }
      }
    }
  }
}
